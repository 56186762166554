/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/* latin-ext */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v14/1Ptug8zYS_SKggPNyC0ITw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

td, th {
    font-family: 'Segoe UI', sans-serif;
    color: black;
    font-size:16px;
}

div, p, span, li, .ui {
    font-family: 'Segoe UI', sans-serif;
    color: white;
}

h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
    color: white;
}

body {
    background: black;
    background-image: url('/dark-bkgd.jpg');
}

.dark-bkgd {
    background: black;
}

.home-bkgd {
    width: 100%;
    background-position: top left;
    background-image: url("/bkgddark.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: auto;
}

.home-margin {
    margin-top: 100px;
    padding-bottom: 15px;
    color: purple;
    font-size: 1.4em;
}

.main-img {
    width: auto;
    max-width: 100%;
}

.firstcell {
    background-color: lightsteelblue;
}

.table-row-hdr {
    background-color: mediumpurple;
}

.center-div {
    display: block;
    width: 50%;
    margin: 0 auto;
}

/* Stripes */

.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table > tr:nth-child(2n) {
    background-color: rgba(0,0,50,.07)
}

.title-text {
    font-size: 2em;
}

h1 {
    font-family: 'Raleway', sans-serif;
    color: mediumpurple;
}

.title-logo {
    height: 35px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.table-max-width {
    width: 500px;
}

.score-text {
    font-family: Verdana;
    color: lightblue;
    margin-left: 7px;
}

.td-hide {
    display: none;
}

.tableHdr, .tdHdr {
    font-size: 1.1em;
}

.sortHdr {
    cursor: pointer;
}

.colored-text {
    color: darkseagreen;
}

.team-text {
    font-style: italic;
    color: saddlebrown;
}

.jumbotron-text {
    margin-bottom:8px;
    font-size:1.2em;
    color:black;
}

.btn-tiny {
    padding: 6px;
    border: 1px solid rosybrown;
}

.td-white-ctr {
    text-align: center;
    color: white;
}

.td-black-ctr {
    text-align: center;
    color: black;
}

.bottom-pad {
    padding-bottom:30px;
}

.edit-label {
    width: 140px; 
    font-weight: bold;
    font-size:14px;
}

.edit-val {
    font-size: 14px;
}

.edit-wholetable {
    border: 1px solid white;
    padding: 6px;
    background: lightgray;
    margin:auto;
    width:800px;
}
.edit-wholetable-sm {
    border: 1px solid white;
    padding: 6px;
    background: lightgray;
    margin: auto;
    width: 500px;
}

.edit-table {
    border: 1px solid white;
    padding: 6px;
    background: lightgray;
}

.td-font {
    font-size: 14px;
    border: 1px solid white;
    padding: 6px;
    background: lightgray;
}

.div-width-sm {
    width:200px;
}

.col-width-sm {
    width: 225px;
}
.col-width-med {
    width: 400px;
}

.edit-th {
    padding: 3px;
    color: black;
    font-weight: bold;
    font-size:16px;
}

.td-label {
    padding: 3px;
    color: black;
    font-weight: bold;
    text-align: left;
    width: 80px;
}

.page-bottom {
    padding-top: 100px;
}

.gray-text {
    color: gray;
}
.green-text {
    color: lawngreen;
}
.red-text {
    color: red;
}

.sm-button {
    border: 1px solid rosybrown;
    padding: 10px;
}

.edit-checkbox {
    height: 25px;
    width: 25px;
    margin-top: -13px;
}

.td-input-sm {
    width: 80px;
    text-align: center;
}

input {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
}

.td-checkbox {
    padding-left: 20px;
}

.btn-edit {
    margin-top: 8px;
}

.hr-purple {
    border: mediumpurple 1px solid;
}