
div, p, h1, span, li, .ui {
    font-family: 'Raleway', sans-serif;
    color: mediumpurple;
}

.blue-text {
    font-family: 'Raleway', sans-serif;
    color: lightblue;
    font-size: 16px;
}